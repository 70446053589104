import React, { useState, useRef } from 'react';

const ImageUploader = ({ onImageUpload }) => {
  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        addSniperScope(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const addSniperScope = (imageSrc) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      const scope = new Image();
      scope.src = '/tribute-maker.png';
      scope.onload = () => {
        canvas.width = scope.width;
        canvas.height = scope.height;
  
        // Define the area where the uploaded image should be placed
        const screenX = 300;
        const screenY = 300;
        const screenWidth = 400;
        const screenHeight = 400;
  
        // Calculate scaling factor to fit the image height
        const scaleHeight = screenHeight / img.height;
        const scaleWidth = screenWidth / img.width;
        const scale = Math.max(scaleHeight, scaleWidth);
  
        // Calculate new dimensions of the image
        const newWidth = img.width * scale;
        const newHeight = img.height * scale;
  
        // Calculate position to center the image within the screen area
        const offsetX = screenX + (screenWidth - newWidth) / 2;
        const offsetY = screenY + (screenHeight - newHeight) / 2;
  
        // Draw the uploaded image within the defined screen area
        ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);
  
        // Draw the scope image on top
        ctx.drawImage(scope, 0, 0);
  
        ctx.font = '30px Arial';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        ctx.fillText('', scope.width / 2, scope.height - 30);
        onImageUpload(canvas.toDataURL());
      };
    };
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div style={{ textAlign: 'center', overflowY: 'visible', maxHeight: '100vh', position: 'absolute', top: '64vh' }}>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <button className="create-tribute-button" onClick={handleButtonClick}>
        Create Tribute
      </button>
    </div>
  );
};

export default ImageUploader;