import React, { useEffect, useState } from 'react';
import './TransitionAnimation.css';

const TransitionAnimation = ({ onAnimationComplete, children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);

    const timer = setTimeout(() => {
      setIsAnimationComplete(true);
      onAnimationComplete();
    }, 2900); // Adjust this time to match your GIF duration

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
    };
  }, [onAnimationComplete]);

  const animationSrc = isMobile ? 'Transition_mobile.gif' : 'Transition_web.gif';

  return (
    <div className="transition-animation">
      <img 
        src={`${process.env.PUBLIC_URL}/${animationSrc}`} 
        alt="Transition" 
        className={isAnimationComplete ? 'fade-out' : ''}
      />
      <div className={`main-page-container ${isAnimationComplete ? 'fade-in' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default TransitionAnimation;