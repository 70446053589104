import './ImagePopup.css';
import React, { useRef, useEffect, useState } from 'react';

const ImagePopup = ({ image, onClose }) => {
  const canvasRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [canShare, setCanShare] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    setCanShare(!!navigator.share);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
    };
    img.src = image;
  }, [image]);

  const copyImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      if (navigator.clipboard && navigator.clipboard.write) {
        // Desktop method (existing code)
        canvas.toBlob((blob) => {
          const item = new ClipboardItem({ "image/png": blob });
          navigator.clipboard.write([item]).then(() => {
            alert('Image copied to clipboard!');
          }).catch(err => {
            console.error('Error copying image: ', err);
          });
        });
      } else {
        // Mobile fallback method
        try {
          canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob);
            const img = document.createElement('img');
            img.style.position = 'absolute';
            img.style.left = '-9999px';
            img.src = url;
            document.body.appendChild(img);
            
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNode(img);
            selection.removeAllRanges();
            selection.addRange(range);
            
            const successful = document.execCommand('copy');
            if (successful) {
              alert('Image copied to clipboard! You can now paste it in other apps.');
            } else {
              alert('Unable to copy image. Please try again or use a different browser.');
            }
            
            document.body.removeChild(img);
            URL.revokeObjectURL(url);
          }, 'image/png');
        } catch (err) {
          console.error('Error copying image: ', err);
          alert('Unable to copy image. Please try again or use a different browser.');
        }
      }
    }
  };

  const saveImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }, 'image/png');
    }
  };

  const shareImage = async () => {
    const canvas = canvasRef.current;
    if (canvas && navigator.share) {
      try {
        const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
        const file = new File([blob], 'image.png', { type: 'image/png' });
        await navigator.share({
          files: [file],
          title: 'Shared Image',
          text: 'Check out this image!',
        });
      } catch (error) {
        console.error('Error sharing image:', error);
        alert('Unable to share image. Please try again.');
      }
    }
  };

  return (
    <div className="image-popup-overlay">
      <div className="image-popup-container">
        <span className="image-popup-close" onClick={onClose}>
          &times;
        </span>
        <div className="image-popup-image-container">
          <canvas ref={canvasRef} className="image-popup-image" />
        </div>
        {isMobile ? (
          <>
            {canShare && (
              <button className="image-popup-copy-button" onClick={shareImage}>
                Share Image
              </button>
            )}
            <button className="image-popup-copy-button" onClick={saveImage}>
              Save Image
            </button>
            <p>Or just take a screenshot</p>
          </>
        ) : (
          <button className="image-popup-copy-button" onClick={copyImage}>
            Copy Image
          </button>
        )}
      </div>
    </div>
  );
};

export default ImagePopup;