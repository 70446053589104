import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import Confetti from 'react-confetti';
import './App.css';
import ImageUploader from './ImageUploader';
import ImagePopup from './ImagePopup';

function MainPage() {
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiPosition, setConfettiPosition] = useState({ x: 0, y: 0 });
  const [confettiConfig, setConfettiConfig] = useState({
    numberOfPieces: 2000,
    initialVelocityX: 5,
    initialVelocityY: 10,
  });
  const timeouts = useRef([]);
  const images = useRef([]);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const imageList = [
    "Shock.png", "Smile.png", "Cringe.png", "Cumtard.png", "Extreme-O.png",
    "Lip-Bite.png", "No-Nut.png", "Cool.png", "Cute.png", "FunnyCoom.png",
    "Happy-Jizzer.png", "HRRRNGG.png", "Pepe.png", "Satisfied.png"
  ];

  const handleImageChange = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
  };
  
  const handleTouchStart = (event) => {
    event.stopPropagation();
  };
  
  const handleTouchEnd = (event) => {
    event.preventDefault();
    handleImageChange();
  };

  const handleImageUpload = (image) => {
    setUploadedImage(image);
    setShowImagePopup(true);
  };

  const handleCloseImagePopup = () => {
    setShowImagePopup(false);
    setUploadedImage(null);
  };

  useEffect(() => {
    const loadImages = async () => {
      try {
        const loadedImages = await Promise.all(
          Array.from({ length: 10 }, (_, i) => {
            const img = new Image();
            img.src = `${process.env.PUBLIC_URL}/c${i + 1}.png`;
            return new Promise((resolve, reject) => {
              img.onload = () => resolve(img);
              img.onerror = () => reject(new Error(`Failed to load image: ${img.src}`));
            });
          })
        );
        images.current = loadedImages;
      } catch (error) {
        console.error('Failed to load images', error);
      }
    };
    loadImages();
  }, []);

  const handleMouseClick = (event) => {
    timeouts.current.forEach(clearTimeout);
    timeouts.current = [];

    setConfettiPosition({ x: event.clientX, y: event.clientY });
    setShowConfetti(true);

    const confettiConfigs = [
      { numberOfPieces: 50, initialVelocityX: 3, initialVelocityY: 5 },
      { numberOfPieces: 200, initialVelocityX: 7, initialVelocityY: 15 },
      { numberOfPieces: 100, initialVelocityX: 5, initialVelocityY: 10 },
    ];

    confettiConfigs.forEach((config, index) => {
      timeouts.current.push(setTimeout(() => setConfettiConfig(config), 2000 * (index + 1)));
    });

    timeouts.current.push(setTimeout(() => setShowConfetti(false), 2500));
  };

  useEffect(() => {
    window.addEventListener('click', handleMouseClick);
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('click', handleMouseClick);
      window.removeEventListener('resize', handleResize);
      timeouts.current.forEach(clearTimeout);
    };
  }, []);

  const drawImage = (ctx) => {
    if (images.current.length > 0) {
      const img = images.current[Math.floor(Math.random() * images.current.length)];
      if (img.complete && img.naturalHeight !== 0) {
        ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);
      }
    }
  };

  return (
    <div className="main-page">
           <img
  src={`${process.env.PUBLIC_URL}/${imageList[currentImageIndex]}`}
  className="bottom-left-image"
  alt="bottom left"
  onClick={handleImageChange}
  onTouchStart={handleTouchStart}
  onTouchEnd={handleTouchEnd}
/>
      <header className="App-header" />
      <div className="button-container">
  {/* <a href="https://pump.fun" target="_blank" rel="noopener noreferrer">
    <img src={`${process.env.PUBLIC_URL}/Streamerz_pumpfun.png`} alt="Pump.Fun" className="nav-button" />
  </a> */}
  <a href="https://dexscreener.com/solana/hz6bnylqdzp6eca47qftg3ygxpmg7berpgqmsp4oxhjk" target="_blank" rel="noopener noreferrer">
    <img src={`${process.env.PUBLIC_URL}/Streamerz_dexscreener.png`} alt="DexScreener" className="nav-button" />
  </a>
  <a href="https://x.com/Tribute_Solana" target="_blank" rel="noopener noreferrer">
    <img src={`${process.env.PUBLIC_URL}/Streamerz_x.png`} alt="X" className="nav-button" />
  </a>
  <a href="https://t.me/TributeonSol" target="_blank" rel="noopener noreferrer">
    <img src={`${process.env.PUBLIC_URL}/Streamerz_telegram.png`} alt="Telegram" className="nav-button" />
  </a>
</div>
      <img 
        src={`${process.env.PUBLIC_URL}/tribute-logo.png`} 
        alt="Tribute Logo" 
        className="tribute-logo" 
      />
      <div className="image-uploader-container">
        <ImageUploader onImageUpload={handleImageUpload} />
      </div>
      <div className="contract-address">CA: 96vfj1VTvnRnvjgSKft7w7TFmdNNCRrpMSsjA1EEgx8w</div>
      {showConfetti && (
        <Confetti
          numberOfPieces={confettiConfig.numberOfPieces}
          recycle={false}
          confettiSource={{ x: confettiPosition.x, y: confettiPosition.y, w: 10, h: 10 }}
          gravity={0.1}
          wind={0}
          drawShape={drawImage}
          initialVelocityY={confettiConfig.initialVelocityY}
          initialVelocityX={confettiConfig.initialVelocityX}
        />
      )}
      {showImagePopup && <ImagePopup image={uploadedImage} onClose={handleCloseImagePopup} />}
    </div>
  );
}

export default MainPage;